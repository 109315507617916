<app-header></app-header>
<div
  elTestId="qFQCwARnBara-myMJxAzT"
  class="e-container -full-width"
>
  <main
    elTestId="Z4_3Mvcz0J6VsPHnbyId6"
    class="content"
  >
    <div
      elTestId="XxeV-fKveVpsadiRBHUdW"
      class="home-container"
    >
      <div
        elTestId="3aNH03Bo_6ZPxZ4RHTxRM"
        class="a-notification -error access-banner"
        role="alert"
      >
        <em
          elTestId="6sEoEyN3GEeynG02IL14U"
          class="a-icon ui-ic-alert-error"
          title="Subscription access"
        ></em>
        <div
          elTestId="ORjp_orttN6Vj0TNshwPW"
          id="notification-label-id-bar-error-error"
          class="a-notification__content access-message"
        >
          You don't have access to subscription.&nbsp;

          @if ((subscriptions$ | async)?.length) {
            <p elTestId="E3WIxGdum-oXIWrFsSRKk">
              Below are the list of subscriptions you have access to.
            </p>
          } @else {
            Please request access.
          }
        </div>
      </div>
      <div
        elTestId="fao-fFnvRZZRcaSzMLNuj"
        class="container service-catalog"
        [ngStyle]="
          !(subscriptions$ | async)?.length
            ? { 'display': 'flex', 'justify-content': 'center' }
            : {}
        "
      >
        @if ((subscriptions$ | async)?.length) {
          <div
            elTestId="mnDkEDU_6EFXkxpqhS_ZF"
            class="a-tile"
          >
            @for (item of subscriptions$ | async; track item) {
              <a
                elTestId="TpmwS334S_4uZOmZTd1sB"
                class="a-tile__link"
                [routerLink]="'/' + item.appConfiguration.id + '/' + item.id"
              >
                <div
                  elTestId="35ianYCrge4EKqxtiJaUt"
                  class="box"
                >
                  <div
                    elTestId="mm53bUKmkbDQZW4hnMEIT"
                    class="inline-flex"
                  >
                    <div elTestId="DyQW3_njG3nKs5eUbVIFw">
                      <div
                        elTestId="fSF3TDVaVgW97OT0q7Wj0"
                        class="app-title"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                  <div
                    elTestId="b9buo4ff8Lifey6089MZ2"
                    class="app-info"
                    title="{{
                      selectedLanguage$
                        | async
                        | i18nSelect: item?.appConfiguration?.description!
                    }}"
                  >
                    {{
                      selectedLanguage$
                        | async
                        | i18nSelect: item?.appConfiguration?.description!
                    }}
                  </div>
                </div>
              </a>
            }
          </div>
        } @else {
          <a
            elTestId="6NLSJ0wctA9G42CTXAN0H"
            href="mailto:help@bosch.io"
            target="_blank"
            rel="noopener"
            role="menuitem"
            class="a-menu-item__link"
            aria-controls="group-id contact"
          >
            <span
              elTestId="qWNJzOrAZISRefjxVqDjG"
              class="a-menu-item__label"
            >
              {{ 'interface.requestAccess' | translate }}
            </span>
          </a>
        }
      </div>
    </div>
  </main>
</div>
<public-footer></public-footer>
