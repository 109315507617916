<app-main-content-container>
  <h2 elTestId="ORvR2w78xEeZd6Gz5UAJ5">
    {{ 'pages.welcome.welcomeToSd' | translate }}
  </h2>
  <br elTestId="1Cnbti_vPHpueES4KV19f" />
  <p elTestId="wQFbDTPCySPO2Val3NfRZ">
    <strong elTestId="EPzh4wDBI-m_QgXHKDQhB">{{
      'pages.welcome.noSubscription' | translate
      }}</strong
      >{{ 'pages.welcome.noSubscriptionHelp' | translate }}
    </p>
    <br elTestId="nTpr8JHaftSIUE73QdouH" />
    <p elTestId="zaQ096gkmQdlKElDQ9x8D">
      {{ 'pages.welcome.intro1' | translate }}
      <strong elTestId="z5moff-Y2aEuvir6AKWbQ">{{
        'pages.welcome.kpiDashboards' | translate
        }}</strong
        >{{ 'pages.welcome.intro2' | translate
        }}<strong elTestId="g_5Aqt5Nw2XYtBSQUGizy">{{
        'pages.welcome.insightsProjects' | translate
        }}</strong
        >{{ 'pages.welcome.intro3' | translate
        }}<strong elTestId="EJ1TAfkmSkRgIAt8mdbWL">{{
        'pages.welcome.customComponents' | translate
        }}</strong
        >{{ 'pages.welcome.intro4' | translate }}
      </p>
      <hr
        elTestId="mBB5wwOj7lnQwUVbCi-y3"
        class="a-divider"
        />
      <br elTestId="2wJ3D9QgBkzsfQ3n6cHEV" />
      <div
        elTestId="-D4Tb26ewYmOEZau0UlTA"
        class="intro-container"
        >
        <div
          elTestId="QPS6hyFvzIwvRggU8Mj0h"
          class="intro-left a-tile"
          >
          <div
            elTestId="vuqBr27fvGP5pniwWSdTb"
            class="view-demo-click-zone"
            >
            <div
              elTestId="2kq1cxYNlJ8emAFs0PzJv"
              class="a-text -text-padding"
              style="padding: 2rem"
              >
              <span
                elTestId="cCmCpsxJglcxkNzrDlmV0"
                class="-size-s"
                style="display: block; margin-bottom: 0.5rem"
                >
                {{ 'pages.welcome.createFreeSubscriptionBox.title' | translate }}
              </span>
              <h3
                elTestId="x3hZvpnG4Ehm253jZu7As"
                class="-size-xl"
                style="margin-bottom: 0.5rem; margin-top: 0"
                >
                {{ 'pages.welcome.createFreeSubscriptionBox.subtitle' | translate }}
              </h3>
              <p
                elTestId="DLBZeWLX5-yz7b59lMFGS"
                class="-size-m"
                style="margin: 0"
            title="{{
              'pages.welcome.createFreeSubscriptionBox.description' | translate
            }}"
                >
                {{
                'pages.welcome.createFreeSubscriptionBox.description' | translate
                }}
              </p>

              <button
                elTestId="WubQabs_1I2icfg77XmRL"
                type="button"
                class="a-button a-button--primary action-button"
                data-frok-action="confirm"
                (click)="joinSandbox()"
                >
                <em
                  elTestId="_IoXAS1Pn33R4rVQP7fgI"
                  class="a-icon a-button__icon boschicon-bosch-ic-add"
                  title="{{ 'redeem.homeRedeemTitle' | translate }}"
                ></em>
                <span
                  elTestId="PWs5CrWca5d9LtODWeF1i"
                  class="a-button__label"
                  >{{
                  'pages.welcome.createFreeSubscriptionBox.confirmButton.text'
                  | translate
                  }}</span
                  >
                </button>
              </div>
            </div>
          </div>
          <div
            elTestId="GD597dFux8JoFgyGvVBMA"
            class="intro-right a-tile -redeem-style"
            >
            <div
              elTestId="62jTg7feS9xr6h5BfW2pq"
              class=""
              >
              <div
                elTestId="P_jCrAiFKRwTPcExKKmZr"
                class="a-text"
                style="padding: 2rem"
                >
                <div elTestId="pjwPtAgK8BqVWimAjw8ga">
                  <span
                    elTestId="CT8aOu4S_bD9A9fh0TZMp"
                    class="-size-s"
                    style="display: block; margin-bottom: 0.5rem"
                    >
                    {{ 'redeem.homeRedeemTitle' | translate }}
                  </span>
                  <h3
                    elTestId="H8zMifxYgX8Va2CF6sJIX"
                    class="-size-xl"
                    style="margin-bottom: 0.5rem; margin-top: 0"
                    >
                    {{ 'redeem.subTitle' | translate }}
                  </h3>
                  <p
                    elTestId="ur-9Zt94Iz1ZwIsKHUCfY"
                    class="-size-m long-text"
                    style="margin: 0"
                    title="{{ 'redeem.description' | translate }}"
                    >
                    {{ 'redeem.description' | translate }}
                  </p>
                  <button
                    elTestId="CBGE4Hw42M54uVcMsYPGR"
                    type="button"
                    class="a-button a-button--primary action-button"
                    data-frok-action="confirm"
                    (click)="showRedeemModal$.next(true)"
                    >
                    <em
                      elTestId="2TD91N1b8UpmnRxV1AH7Z"
                      class="a-icon a-button__icon boschicon-bosch-ic-keys"
                      title="{{ 'redeem.homeRedeemTitle' | translate }}"
                    ></em>
                    <span
                      elTestId="wy6E9YDg6MNSLMkUxLHrG"
                      class="a-button__label"
                      >{{ 'redeem.homeRedeemTitle' | translate }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          @if ((showRedeemModal$ | async)!) {
            <app-redeem-code
              (close)="showRedeemModal$.next(false)"
            ></app-redeem-code>
          }
        </app-main-content-container>
